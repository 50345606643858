import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Typography,
} from '@mui/material'
import MuiTable, { TableRow, customCell } from 'Component/MuiTable'
import React, { useEffect, useState } from 'react'

import { ExpandMore } from '@mui/icons-material'
import Modal from 'Component/Modal'
import { Query } from 'helpers/utils/__generated__/resolvers-types'
import { USER } from 'helpers/graphql/query/user/index.graphql'
import { format } from 'date-fns'
import { useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

type UserModalProps = {
  user: string | null
  onClose: () => void
}

const UserModal = (props: UserModalProps) => {
  const { user: userToken, onClose } = props

  const [expanded, setExpanded] = useState('')
  const [fetch, { data }] = useLazyQuery<Query>(USER)

  const navigate = useNavigate()

  useEffect(() => {
    if (userToken) {
      fetch({ variables: { token: userToken } })
    }
  }, [userToken])

  const user = data?.AdminUsers.users[0]

  if (!user) return null

  const headersSheets: TableRow = [
    customCell('#Id'),
    customCell('Titre'),
    customCell('Date', 'right'),
    '',
  ]

  const rowsSheets: TableRow[] = user.sheets.map(sheet => [
    sheet.id,
    sheet.title,
    customCell(format(new Date(sheet.date), 'dd/MM/yyyy'), 'right'),
    customCell(
      <a href={`/sheet/${sheet.id}`} target="_blank">
        <Button>Voir</Button>,
      </a>,
      'right',
    ),
  ])

  const headersDrafts: TableRow = [
    customCell('Titre'),
    customCell('Date', 'right'),
  ]

  const rowsDrafts: TableRow[] = user.drafts.map(sheet => [
    sheet.title,
    customCell(format(new Date(sheet.date), 'dd/MM/yyyy'), 'right'),
  ])

  return (
    <Modal open={!!userToken} onClose={onClose}>
      <div className="row">
        <div className="d-flex align-items-center col-12">
          <Avatar className={'mx-2'}>
            {user.photo ? (
              <img
                src={
                  process.env.REACT_APP_SERVER + 'user/image?file=' + user.photo
                }
                className={'w-100'}
              />
            ) : (
              <></>
            )}
          </Avatar>
          <Typography variant={'h3'} component={'p'}>
            {user.firstname + ' ' + user.lastname}
          </Typography>
        </div>

        <div className={'col-12'}>
          Email: {user.email}
          <br />
          Téléphone: {user.phone}
          <br />
          Date de naissance:{' '}
          {user.birthday ? format(+user.birthday, 'dd/MM/yyyy') : 'N/A'}
          <br />
          Adresse :
          <br />
          {user.address}
          <br />
          {user.zip ? user.zip + ', ' : ''} {user.city}
        </div>
        <div className="col-12">
          <Typography variant={'h3'} component={'p'} align={'center'}>
            Fiches
          </Typography>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={() => {
              setExpanded('panel1')
            }}
            className={'sheets-list'}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>Brouillons</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row p-0 w-100 div-table">
                {user.drafts ? (
                  <MuiTable rows={rowsDrafts} headers={headersDrafts} />
                ) : (
                  <></>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={() => {
              setExpanded('panel2')
            }}
            className={'sheets-list'}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>Fiches publiées</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row p-0 w-100 div-table">
                {user.sheets ? (
                  <MuiTable rows={rowsSheets} headers={headersSheets} />
                ) : (
                  <></>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </Modal>
  )
}
export default UserModal
